import img1 from "../../assets/image/1.svg";
import img2 from "../../assets/image/2.svg";
import img3 from "../../assets/image/3.svg";
import img4 from "../../assets/image/4.svg";
import img5 from "../../assets/image/5.svg";
import img6 from "../../assets/image/6.svg";
import img7 from "../../assets/image/7.svg";
import img8 from "../../assets/image/8.svg";
import img9 from "../../assets/image/9.svg";
import img10 from "../../assets/image/10.svg";
import img11 from "../../assets/image/11.svg";
import img12 from "../../assets/image/12.svg";
import img13 from '../../assets/image/img13.jpeg'
import img14 from '../../assets/image/img14.jpeg'
import img15 from '../../assets/image/img15.jpeg'
import img16 from '../../assets/image/img16.jpeg'
import img17 from '../../assets/image/img17.jpeg'
import img18 from '../../assets/image/img18.jpeg'
import img19 from '../../assets/image/img19.jpeg'
import img20 from '../../assets/image/img20.jpeg'

export const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  },
  {
    original: img9,
    thumbnail: img9,
  },
  {
    original: img10,
    thumbnail: img10,
  },
  {
    original: img11,
    thumbnail: img11,
  },
  {
    original: img12,
    thumbnail: img12,
  },
  {
    original: img13,
    thumbnail: img13,
  },
  {
    original: img14,
    thumbnail: img14,
  },
  {
    original: img15,
    thumbnail: img15,
  },
  {
    original: img16,
    thumbnail: img16,
  },
  {
    original: img17,
    thumbnail: img17,
  },
  {
    original: img18,
    thumbnail: img18,
  },
  {
    original: img19,
    thumbnail: img19,
  },
  {
    original: img20,
    thumbnail: img20,
  },
];
