import "./footer.style.scss";
import FbIcon from "../../../assets/image/fb-icon.svg";

function Footer() {
  return (
    <div className="footer-comp">
      <div className="footer-item">
        <a href="https://www.facebook.com/share/qBPkDPadPbVv9wEY/?mibextid=LQQJ4d">
          <img className="fb-icon" src={FbIcon} alt="" />
          <h3>New Day</h3>
        </a>
        <p className="f8-color text-16">info@new-day.ca</p>
        <p className="white-color text-16">Hotline: 1-(825)-561-7699</p>
        <p className="white-color text-16">
          Address: 218 Chaparral Place SE, Calgary AB, Canada T2X 3K5
        </p>
        <p style={{ color: "#9AACBC" }} className="text-14">
          Copyright © 2024 New Day - All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
