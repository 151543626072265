import React, { Fragment } from "react";
import CultureImg from "../../../assets/image/culture-img.svg";
import Logo1 from "../../../assets/image/culture-logo1.svg";
import Logo2 from "../../../assets/image/culture-logo2.svg";
import '../about/about.style.scss';

const Culture = () => {
  return (
    <div className="about-comp">
      <div className="culture">
        <div className="detail">
          <div className="description">
            <p
              style={{ marginTop: "20px" }}
              className="title-detail font-archivo"
            >
              OUR CULTURE
            </p>
            <p className="info-detail">
              Pairing a Certified Master Electrician with a team inspired safety
              culture. We ensure your job is code compliant and done with a high
              safety standard.
            </p>
            <div className="logo-culture">
              <img src={Logo1} alt="" />
              <img src={Logo2} alt="" />
            </div>
          </div>
        </div>
        <div className="detail-img">
          <img className="img-detail" src={CultureImg} alt="" />
        </div>
      </div>

      {/* mobile */}
      <div className="culture-mobile">
        <div className="detail-mobile">
          <p
            style={{ marginTop: "20px" }}
            className="title-detail font-archivo"
          >
            OUR CULTURE
          </p>
          <p className="info-detail">
            Pairing a Certified Master Electrician with a team inspired safety
            culture. We ensure your job is code compliant and done with a high
            safety standard.
          </p>
          <div className="logo-culture">
            <div className="img-culture">
              <img src={Logo1} alt="" />
              <img src={Logo2} alt="" />
            </div>
          </div>
          <img className="img-detail" src={CultureImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Culture;
