import AboutImg from "../../../assets/image/logo-about.svg";
import "./about.style.scss";


function About() {
  return (
    <div className="about-comp">
      <div className="about">
        <div className="detail-img">
          <img className="img-detail" src={AboutImg} alt="" />
        </div>
        <div className="detail">
          <div className="description">
            <p
              style={{ marginTop: "36px" }}
              className="title-detail font-archivo"
            >
              ABOUT NEWDAY
            </p>
            <p className="info-detail">
              New-Day Construction was founded in 2019 based on a common belief
              to provide great customer service.  After the pandemic, New-Day
              saw a need for a quality finished product for home owners and
              general contractors at a reasonable price.  With a combined 30+
              years’ experience in the industry, New-Day is a family orientated
              business focused on customer support and quality work. 
              Experienced in commercial high rise, commercial servicing,
              residential, and new construction.  New-Day is currently growing
              into residential new builds, retro fitting residential high-rise
              buildings, and beyond.  Look for our logo around the city of
              Calgary and surrounding areas as we continue to grow.
            </p>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="about-mobile">
        <div className="detail-mobile">
          <p
            style={{ marginTop: "36px" }}
            className="title-detail font-archivo"
          >
            ABOUT NEWDAY
          </p>
          <img className="img-detail" src={AboutImg} alt="" />
          <p className="info-detail">
            New-Day Construction was founded in 2019 based on a common belief to
            provide great customer service.  After the pandemic, New-Day saw a
            need for a quality finished product for home owners and general
            contractors at a reasonable price.  With a combined 30+ years’
            experience in the industry, New-Day is a family orientated business
            focused on customer support and quality work.  Experienced in
            commercial high rise, commercial servicing, residential, and new
            construction.  New-Day is currently growing into residential new
            builds, retro fitting residential high-rise buildings, and beyond. 
            Look for our logo around the city of Calgary and surrounding areas
            as we continue to grow.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
