import About from "../../core/about/about.component";
import Contact from "../../core/contact/contact.component";
import Culture from "../../core/culture/culture.component";
import Footer from "../../core/footer/footer.component";
import Home from "../../core/home/home.component";
import Navbar from "../../core/navbar/navbar.component";
import Project from "../../core/project/project.component";
import Service from "../../core/service/service.component";
import "./newday.style.scss";

function NewDay() {
  return (
    <div className="newday-box">
      <div className="navbar-box">
        <Navbar />
      </div>

      <div id="home" className="home-box">
        <Home />
      </div>

      <div id="about" className="about-box">
        <About />
      </div>

      <div id="culture" className="about-box">
        <Culture />
      </div>

      <div id="service" className="service-box">
        <Service />
      </div>

      <div id="project" className="project-box">
        <Project />
      </div>

      <div id="contact" className="contact-box">
        <Contact />
      </div>

      <div className="footer-box">
        <Footer />
      </div>
    </div>
  );
}

export default NewDay;
