import { useState } from "react";
import "./project.style.scss";
import ImageGallery from "react-image-gallery";
import { images } from "../../api/images";

function Project() {
  const [panels, setPanels] = useState([0, 1, 2, 3, 4]);
  return (
    <div className="project-comp">
      <h2 className="title font-archivo">PROJECT</h2>
      <div className="slider">
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>
    </div>
  );
}

export default Project;
