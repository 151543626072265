import logoNav from "../../../assets/image/logo-nav.svg";
import { Link } from "react-router-dom";
import "./navbar.style.scss";
import { useState } from "react";
import IconMenu from "../../../assets/image/icon-menu.svg";

function Navbar() {
  const [selectedTab, setSelectedTab] = useState("home");
  const [openMenu, setOpenMenu] = useState(false);

  const selectTab = (tab: string) => {
    setSelectedTab(tab);
    const element = document.getElementById(tab);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const getTabStyle = (tab: string) => {
    return {
      color: selectedTab === tab ? "#fff" : "#f8f8f8",
      fontWeight: selectedTab === tab ? "bold" : "normal",
    };
  };
  const handleShow = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="nav-comp">
      {/* logo */}
      <div className="logo-nav" id="homeTop" onClick={() => selectTab("home")}>
        <img src={logoNav} alt="" />
      </div>
      {/* option */}
      <div className="list-option">
        <Link to="/home" onClick={() => selectTab("home")}>
          <p style={getTabStyle("home")}>HOME</p>
        </Link>
        <Link to="/home" onClick={() => selectTab("about")}>
          <p style={getTabStyle("about")}>ABOUT US</p>
        </Link>
        <Link to="/home" onClick={() => selectTab("culture")}>
          <p style={getTabStyle("culture")}>CULTURE</p>
        </Link>
        <Link to="/home" onClick={() => selectTab("service")}>
          <p style={getTabStyle("service")}>SERVICE</p>
        </Link>
        <Link to="/home" onClick={() => selectTab("project")}>
          <p style={getTabStyle("project")}>PROJECT</p>
        </Link>
        <Link to="/home" onClick={() => selectTab("contact")}>
          <p style={getTabStyle("contact")}>CONTACT</p>
        </Link>
      </div>
      {/* mobile */}
      <div className="icon-menu">
        <div onClick={handleShow}>
          <img src={IconMenu} alt="" />
        </div>

        <div
          className="list-option-mobile"
          style={{ display: openMenu ? "block" : "none" }}
        >
          <div
            style={{ paddingRight: "16px" }}
          >
            <Link to="/home" onClick={() => selectTab("home")}>
              <p style={getTabStyle("home")}>HOME</p>
            </Link>
            <p className="separate"></p>
            <Link to="/home" onClick={() => selectTab("about")}>
              <p style={getTabStyle("about")}>ABOUT US</p>
            </Link>
            <p className="separate"></p>
            <Link to="/home" onClick={() => selectTab("culture")}>
              <p style={getTabStyle("culture")}>CULTURE</p>
            </Link>
            <p className="separate"></p>
            <Link to="/home" onClick={() => selectTab("project")}>
              <p style={getTabStyle("project")}>PROJECT</p>
            </Link>
            <p className="separate"></p>
            <Link to="/home" onClick={() => selectTab("service")}>
              <p style={getTabStyle("service")}>SERVICE</p>
            </Link>
            <p className="separate"></p>
            <Link to="/home" onClick={() => selectTab("contact")}>
              <p style={getTabStyle("contact")}>CONTACT</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
